(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/header/helpers/header-helpers.js') >= 0) return;  svs.modules.push('/components/components/header/helpers/header-helpers.js');

(function() {
  'use strict';

  function registerHelpers(hbs, isServerSide) {
    hbs.registerHelper('setHeaderSize', function(cssClass, options) {
      var isDesktop = false;

      if (isServerSide) {
        isDesktop = options.data.root.capabilities.formFactor === 'desktop';
      } else {
        isDesktop = svs.core.detect.formfactor.desktop();
      }

      return isDesktop ?
        cssClass :
        cssClass.replace('icon-header-dynamic', 'icon-header');
    });

    hbs.registerHelper('internalAlias', function() {
      return svs.components.Storage.browser.get(
        'internalAlias',
        'svs.components.login'
      );
    });
  }

  if (typeof exports === 'object') {
    registerHelpers(require('hbs'), true);
  } else {
    registerHelpers(Handlebars, false);
  }
})();


 })(window);