(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/header/assets/javascripts/header.js') >= 0) return;  svs.modules.push('/components/components/header/assets/javascripts/header.js');

'use strict';

function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
svs.components = svs.components || {};
class Header {
  constructor(templates) {
    var _svs$core$config$data, _svs$core$config$data2;
    _defineProperty(this, "templateName", (_svs$core$config$data = svs.core.config.data.svsconfig) !== null && _svs$core$config$data !== void 0 && _svs$core$config$data.isSelfService ? 'svsheader_selfservice_title' : 'svsheader');
    _defineProperty(this, "$el", (_svs$core$config$data2 = svs.core.config.data.svsconfig) !== null && _svs$core$config$data2 !== void 0 && _svs$core$config$data2.isSelfService ? $('.js-header-selfservice-title') : $('.js-top-content'));
    _defineProperty(this, "$window", $(window));
    _defineProperty(this, "$body", $(document.body));
    _defineProperty(this, "$fixedTopContent", $('.js-fixed-top-content'));
    _defineProperty(this, "$topContentWrapper", $('.js-top-content-wrapper'));
    _defineProperty(this, "$fixedTopContentHeight", void 0);
    _defineProperty(this, "$topContentWrapperHeight", void 0);
    _defineProperty(this, "noShadowCSSClass", 'header-no-shadow');
    _defineProperty(this, "noShadowCSSClassBranding", '');
    _defineProperty(this, "log", new svs.core.log.Logger('svs.components.Header'));
    _defineProperty(this, "templates", void 0);
    _defineProperty(this, "listeningToScroll", false);
    _defineProperty(this, "triggerVisibility", false);
    _defineProperty(this, "limitToBreakpoints", []);
    _defineProperty(this, "headerVisibilityThreshold", svs.core.userSession.hasRole(svs.core.userSession.roles.PLAYER) ? 88 : 44);
    _defineProperty(this, "headerVisible", true);
    _defineProperty(this, "_changeListener", () => this._listenToBreakpointChange());
    _defineProperty(this, "_scrollListener", _.throttle(() => {
      const scrollTop = this.$window.scrollTop();
      if (this.triggerVisibility) {
        this._headerVisibilityChange(this._getHeaderVisibility(scrollTop));
      }
      if (this.hasInfobarAtTopFlag) {
        this._infobarVisibilityChange(scrollTop > 0);
      }
    }, 50));
    _defineProperty(this, "setShrinkableHeader", () => {
      this._setupHeaderScaleListerners();
    });
    this.templates = templates;
    svs.core.detect.breakpoint.on('change', () => this.updateCachedHeights());
    if (!this.$topContentWrapper.length) {
      this.$topContentWrapper = this.$fixedTopContent;
    }
    this.$window.on('scroll', this._scrollListener);
    this.setupMobileListeners();
    svs.core.template.on('configUpdate', e => this.onTemplateConfigUpdate(e));
  }
  _setupHeaderScaleListerners() {
    let isMaxHeight;
    let navShowing;
    const self = this;
    const body = document.body;
    const maxHeaderHeight = 88;
    const updateHeaderScale = () => {
      const currentNavShowing = svs.core.detect.breakpoint.current === 'lg' || svs.core.detect.breakpoint.current === 'xl';
      const currentIsMaxHeight = window.pageYOffset >= maxHeaderHeight;
      if (currentNavShowing !== navShowing) {
        if (currentNavShowing) {
          body.classList.add('header-shrinkable');
        } else {
          body.classList.remove('header-shrinkable');
          body.classList.remove('header-shrinked');
        }
        self.updateCachedHeights();
      }
      if (currentNavShowing) {
        if (isMaxHeight !== currentIsMaxHeight || currentNavShowing !== navShowing) {
          if (currentIsMaxHeight) {
            body.classList.add('header-shrinked');
          } else {
            body.classList.remove('header-shrinked');
          }
          setTimeout(() => {
            self.updateCachedHeights();
          }, 250);
        }
      }
      isMaxHeight = currentIsMaxHeight;
      navShowing = currentNavShowing;
    };
    body.classList.add('header-shrinkable');
    window.addEventListener('resize', updateHeaderScale, {
      passive: true
    });
    window.addEventListener('scroll', updateHeaderScale, {
      passive: true
    });
    updateHeaderScale();
  }
  _getHeaderVisibility() {
    let scrollTop = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.$window.scrollTop();
    const result = scrollTop < this.headerVisibilityThreshold;
    if (result) {
      this.trigger('headerVisibleScroll', scrollTop);
    }
    return result;
  }
  _headerVisibilityChange(visible) {
    if (this.headerVisible !== visible) {
      this.headerVisible = Boolean(visible);
      if (this.headerVisible) {
        this.trigger('headerVisibilityChange', true);
        this.$body.removeClass('header-hidden');
      } else {
        this.trigger('headerVisibilityChange', false);
        this.$body.addClass('header-hidden');
      }
      this.trigger('headerVisibleScroll', this.$window.scrollTop());
    }
  }
  updateCachedHeights() {
    if (this.$fixedTopContentHeight) {
      this.$fixedTopContentHeight = this.$fixedTopContent.height();
    }
    if (this.$topContentWrapperHeight) {
      this.$topContentWrapperHeight = this.$topContentWrapper.height();
    }
  }
  getFixedTopContentWrapperHeight() {
    if (!this.$fixedTopContentHeight) {
      this.$fixedTopContentHeight = this.getFixedTopContentWrapper().height();
    }
    return this.$fixedTopContentHeight;
  }
  getTopContentWrapperHeight() {
    if (!this.$topContentWrapperHeight) {
      this.$topContentWrapperHeight = this.getTopContentWrapper().height();
    }
    return this.$topContentWrapperHeight;
  }
  getFixedTopContentWrapper() {
    return this.$fixedTopContent;
  }
  setFixedTopContentWrapper($newWrapper) {
    if (this.$fixedTopContent === this.$topContentWrapper) {
      this.$fixedTopContent = this.$topContentWrapper = $newWrapper;
      delete this.$fixedTopContentHeight;
      delete this.$topContentWrapperHeight;
    } else {
      delete this.$fixedTopContentHeight;
      this.$fixedTopContent = $newWrapper;
    }
  }
  getTopContentWrapper() {
    return this.$topContentWrapper;
  }
  _listenToBreakpointChange() {
    this.triggerVisibility = Boolean(~this.limitToBreakpoints.indexOf(svs.core.detect.breakpoint.current));
    if (!this.triggerVisibility) {
      this._headerVisibilityChange(true);
    } else {
      this._listenToScroll(this.triggerVisibility);
      this._headerVisibilityChange(this._getHeaderVisibility());
    }
  }
  _listenToScroll(listen) {
    if (this.listeningToScroll !== listen) {
      if (listen) {
        this.listeningToScroll = true;
        this.$window.on('scroll', this._scrollListener);
      } else if (!listen && !this.hasInfobarAtTopFlag && !this.triggerVisibility) {
        this.listeningToScroll = false;
        this.$window.off('scroll', this._scrollListener);
      }
    }
  }
  triggerOnHeaderVisibilityChange(triggerVisibility, limitToBreakpoints) {
    if (triggerVisibility && this.limitToBreakpoints.length) {
      if (limitToBreakpoints) {
        this.log.info('headerVisibilityTrigger called again when already set with limits, merging limits');
        this.limitToBreakpoints = _.union(this.limitToBreakpoints, limitToBreakpoints);
      } else {
        this.log.info('headerVisibilityTrigger called again when already set with limits, removing limits');
        this.limitToBreakpoints.length = 0;
        svs.core.detect.breakpoint.off('change', this._changeListener);
        this._listenToScroll(this.triggerVisibility);
        this._scrollListener();
      }
    } else if (triggerVisibility !== this.triggerVisibility) {
      this.triggerVisibility = Boolean(triggerVisibility);
      this.limitToBreakpoints = this.triggerVisibility ? limitToBreakpoints : this.limitToBreakpoints.length = 0;
      if (limitToBreakpoints) {
        if (this.triggerVisibility) {
          svs.core.detect.breakpoint.on('change', this._changeListener);
          this._listenToBreakpointChange();
        } else {
          svs.core.detect.breakpoint.off('change', this._changeListener);
          this._listenToScroll(false);
        }
      } else {
        svs.core.detect.breakpoint.off('change', this._changeListener);
        this._listenToScroll(this.triggerVisibility);
        this._scrollListener();
      }
    }
  }
  hasChanged(e, key) {
    return e.changed.indexOf(key) !== -1;
  }
  onTemplateConfigUpdate(e) {
    if (this.hasChanged(e, 'headerClass')) {
      this.changeTopContent(e.config.headerClass);
    }
    if (this.hasChanged(e, 'headerNoScrollClass')) {
      this.noShadowCSSClassBranding = e.config.headerNoScrollClass ? e.config.headerNoScrollClass : '';
      if (this.noShadowCSSClassBranding) {
        this.$window.on('scroll', this._scrollListener);
      } else {
        this.$window.off('scroll', this._scrollListener);
      }
      this.handleScroll();
    }
    if (this.hasChanged(e, 'title') || this.hasChanged(e, 'leftButtons') || this.hasChanged(e, 'rightButtons')) {
      var _svs$core$config$data3;
      let data;
      if ((_svs$core$config$data3 = svs.core.config.data.svsconfig) !== null && _svs$core$config$data3 !== void 0 && _svs$core$config$data3.isSelfService) {
        data = e.config.title;
      } else {
        data = {
          title: e.config.title,
          leftButtons: e.config.leftButtons,
          rightButtons: e.config.rightButtons
        };
      }
      this.render(data);
      this.setupButtonListeners(e.config.leftButtons);
      this.setupButtonListeners(e.config.rightButtons);
      this.setupMobileListeners();
    }
  }
  contextMenuClick(e) {
    e.preventDefault();
    svs.core.module.load('/cl/components/contextual-menu', null, error => {
      if (error) {
        this.log.error('Failed to load components/contextual-menu', error);
      }
      if (svs.components.contextualMenu) {
        svs.components.contextualMenu.toggleVisibility();
      }
    });
  }

  changeTopContent(cssClass) {
    const topContentEl = document.getElementById('top-content');
    const classes = topContentEl.className.split(/\s+/);
    for (const className of classes) {
      if (/header-\w*/i.test(className)) {
        topContentEl.classList.remove(className);
      }
    }
    topContentEl.classList.add(cssClass);
  }

  render(data) {
    this.$el.html(this.templates[this.templateName](data || {}));
  }

  setupMobileListeners() {
    if (svs.core.detect.formfactor.mobile()) {
      $('.js-top-contextual').on('click', this.contextMenuClick.bind(this));
    }
  }

  setupButtonListeners(buttons) {
    if (buttons) {
      for (let i = 0; i < buttons.length; i++) {
        if (buttons[i].id && buttons[i].action) {
          $("#".concat(buttons[i].id)).on('click', buttons[i].action);
        }
      }
    }
  }

  handleScroll() {
    if (this.$window.scrollTop() === 0) {
      this.$el.addClass(this.noShadowCSSClass).addClass(this.noShadowCSSClassBranding);
    } else {
      this.$el.removeClass(this.noShadowCSSClass).removeClass(this.noShadowCSSClassBranding);
    }
  }
}
svs.components.header = new svs.core.Events(new Header(svs.components.header.templates));

 })(window);