(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/header/views/svsheader-selfservice-title.js') >= 0) return;  svs.modules.push('/components/components/header/views/svsheader-selfservice-title.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_cmps=_svs.components=_svs.components||{};
_cmps.header=_cmps.header||{};
_cmps.header.templates=_cmps.header.templates||{};
svs.components.header.templates.svsheader_selfservice_title = Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"js-header-selfservice-title\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"components-components-header-svsheader_centercontent"),depth0,{"name":"components-components-header-svsheader_centercontent","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</div>\n";
},"usePartial":true,"useData":true});
Handlebars.partials['components-components-header-svsheader_selfservice_title'] = svs.components.header.templates.svsheader_selfservice_title;
})(svs, Handlebars);


 })(window);