(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/header/views/svsheader_user_desktop.js') >= 0) return;  svs.modules.push('/components/components/header/views/svsheader_user_desktop.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_cmps=_svs.components=_svs.components||{};
_cmps.header=_cmps.header||{};
_cmps.header.templates=_cmps.header.templates||{};
svs.components.header.templates.svsheader_user = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div class=\"padding-xs-top-1 padding-xs-h-2 fc-white header-user-info js-header-user-info firstname user-logged-in\" tabindex=\"0\">\n    <div class=\"left align-left f-400\">\n      <i class=\"icon-default icon-user header-user-info-profilepic\"></i>\n      <span class=\"data-hj-suppress\">"
    + alias3(((helper = (helper = lookupProperty(helpers,"firstName") || (depth0 != null ? lookupProperty(depth0,"firstName") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"firstName","hash":{},"data":data,"loc":{"start":{"line":5,"column":37},"end":{"line":5,"column":50}}}) : helper)))
    + "</span>\n    </div>\n    <div class=\"right\">\n      <i class=\"icon-default js-toggle-user-menu icon-100 icon-menu-down\"></i>\n    </div>\n  </div>\n  <div class=\"header-user-info-dropdown\">\n    <div class=\"grid-row\">\n      <div class=\"col-sm-12 btn-balance-toggle js-toggle-balance\">\n        <div class=\"js-balance-loader header-user-info-dropdown-balance-loader absolute\"></div>\n      </div>\n      <div class=\"user-menu-links\">\n        <div class=\"col-sm-12 padding-xs-v padding-xs-h-2\">\n          <a href=\""
    + alias3((lookupProperty(helpers,"urlMapping")||(depth0 && lookupProperty(depth0,"urlMapping"))||alias2).call(alias1,"transactionsHome",{"name":"urlMapping","hash":{},"data":data,"loc":{"start":{"line":18,"column":19},"end":{"line":18,"column":52}}}))
    + "\">\n            "
    + ((stack1 = (lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"button",{"name":"ui","hash":{"block":true,"inverted":true,"transparent":true,"size":300},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":12},"end":{"line":19,"column":98}}})) != null ? stack1 : "")
    + "\n          </a>\n        </div>\n        <div class=\"col-sm-12 padding-xs-v padding-xs-h-2\">\n          <a href=\""
    + alias3((lookupProperty(helpers,"urlMapping")||(depth0 && lookupProperty(depth0,"urlMapping"))||alias2).call(alias1,"myProfileHome",{"name":"urlMapping","hash":{},"data":data,"loc":{"start":{"line":23,"column":19},"end":{"line":23,"column":49}}}))
    + "\">\n            "
    + ((stack1 = (lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"button",{"name":"ui","hash":{"block":true,"inverted":true,"transparent":true,"size":300},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":12},"end":{"line":24,"column":96}}})) != null ? stack1 : "")
    + "\n          </a>\n        </div>\n      </div>\n      <div class=\"col-sm-12 padding-xs-2\">\n        "
    + ((stack1 = (lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"button",{"name":"ui","hash":{"class":"js-logout","block":true,"size":300},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":8},"end":{"line":29,"column":77}}})) != null ? stack1 : "")
    + "\n      </div>\n    </div>\n  </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "Överföringar";
},"4":function(container,depth0,helpers,partials,data) {
    return "Min profil";
},"6":function(container,depth0,helpers,partials,data) {
    return "Logga ut";
},"8":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div class=\"grid-row padding-xs-top-1 padding-xs-h-2 fc-white header-user-info js-header-user-info firstname user-logged-in\" tabindex=\"0\">\n        <span class=\"col-sm-9 align-left f-400\">\n          <i class=\"icon-default icon-user header-user-info-profilepic\"></i>\n          "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"internalAlias") || (depth0 != null ? lookupProperty(depth0,"internalAlias") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"internalAlias","hash":{},"data":data,"loc":{"start":{"line":39,"column":10},"end":{"line":39,"column":27}}}) : helper)))
    + "\n        </span>\n        <span class=\"col-sm-2 align-right\">\n        </span>\n    <span class=\"col-sm-1\"><i class=\"icon-default js-toggle-user-menu icon-100 icon-menu-down\"></i></span>\n  </div>\n  <div class=\"header-user-info-dropdown header-user-info-dropdown-interal\">\n    <div class=\"grid-row\">\n      <div class=\"col-sm-12 padding-2\"><input type=\"button\" class=\"btn btn-default btn-block js-logout\"  value=\"Logga ut\" /></div>\n    </div>\n  </div>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"grid-row padding-xs-top-1 padding-xs-h-2 fc-white header-user-info js-header-user-info firstname user-logged-in\" tabindex=\"0\">\n        <span class=\"col-sm-9 align-left f-400\">\n          <i class=\"icon-default icon-user header-user-info-profilepic\"></i>\n          "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"internalAlias") || (depth0 != null ? lookupProperty(depth0,"internalAlias") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"internalAlias","hash":{},"data":data,"loc":{"start":{"line":55,"column":10},"end":{"line":55,"column":27}}}) : helper)))
    + "\n        </span>\n        <span class=\"col-sm-2 align-right\">\n        </span>\n        <span class=\"col-sm-1\"><i class=\"icon-default js-toggle-user-menu icon-menu-down\"></i></span>\n    </div>\n    <div class=\"header-user-info-dropdown header-user-info-dropdown-interal\">\n        <div class=\"grid-row\">\n            <div class=\"col-sm-12 padding-2\"><input type=\"button\" class=\"btn btn-default btn-block js-logout\"  value=\"Logga ut\" /></div>\n        </div>\n    </div>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div class=\"col-xs-6 col-login\">\n    <a href=\""
    + alias3((lookupProperty(helpers,"urlMapping")||(depth0 && lookupProperty(depth0,"urlMapping"))||alias2).call(alias1,"loginHome",{"name":"urlMapping","hash":{},"data":data,"loc":{"start":{"line":101,"column":13},"end":{"line":101,"column":39}}}))
    + "\" id=\"start-page-login\" class=\"js-open-login-button fc-white padding-xs-1 header-login-buttons\">Logga in</a>\n  </div>\n  <div class=\"col-xs-6 col-register\">\n    <a href=\""
    + alias3((lookupProperty(helpers,"urlMapping")||(depth0 && lookupProperty(depth0,"urlMapping"))||alias2).call(alias1,"customerRegistrationHome",{"name":"urlMapping","hash":{},"data":data,"loc":{"start":{"line":104,"column":13},"end":{"line":104,"column":54}}}))
    + "\" id=\"start-page-register\" class=\"fc-white padding-xs-h-1 padding-md-v-1 header-login-buttons\">Bli kund</a>\n  </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  ((stack1 = (lookupProperty(helpers,"hasRole")||(depth0 && lookupProperty(depth0,"hasRole"))||alias2).call(alias1,{"name":"hasRole","hash":{"role":"Player"},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":33,"column":12}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (lookupProperty(helpers,"hasRole")||(depth0 && lookupProperty(depth0,"hasRole"))||alias2).call(alias1,{"name":"hasRole","hash":{"role":"Internal"},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":0},"end":{"line":50,"column":12}}})) != null ? stack1 : "")
    + ((stack1 = (lookupProperty(helpers,"hasRole")||(depth0 && lookupProperty(depth0,"hasRole"))||alias2).call(alias1,{"name":"hasRole","hash":{"role":"Retailer"},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":51,"column":0},"end":{"line":66,"column":12}}})) != null ? stack1 : "")
    + ((stack1 = (lookupProperty(helpers,"hasRole")||(depth0 && lookupProperty(depth0,"hasRole"))||alias2).call(alias1,{"name":"hasRole","hash":{"role":"RetailerCorp"},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":67,"column":0},"end":{"line":82,"column":12}}})) != null ? stack1 : "")
    + ((stack1 = (lookupProperty(helpers,"hasRole")||(depth0 && lookupProperty(depth0,"hasRole"))||alias2).call(alias1,{"name":"hasRole","hash":{"role":"ExternalPartner"},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":83,"column":0},"end":{"line":98,"column":12}}})) != null ? stack1 : "");
  stack1 = ((helper = (helper = lookupProperty(helpers,"isAnonymous") || (depth0 != null ? lookupProperty(depth0,"isAnonymous") : depth0)) != null ? helper : alias2),(options={"name":"isAnonymous","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":99,"column":0},"end":{"line":106,"column":16}}}),(typeof helper === "function" ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isAnonymous")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true});
Handlebars.partials['components-components-header-svsheader_user'] = svs.components.header.templates.svsheader_user;
})(svs, Handlebars);


 })(window);